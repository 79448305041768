@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Permanent+Marker&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'montseratt', sans-serif;
}

.weather-container {
    background-image: url('./assets/cold-weather.jpg');
    background-size: cover;
    flex-grow: 1;
    min-height: 100vh;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;
    transition: 0.4 ease-out;
}

.weather-container .warm {
    background-image: url('./assets/mountain-sunrise.jpg');
}

main {
    min-height: 100vh;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2),  rgba(0, 0, 0, 0.55));
    padding: 25px;
}

.search-box {
    width: 100%;
    margin: 0 0 45px;
}

.search-box .search-bar{
    display: block;
    width: 100%;
    padding: 15px;
    appearance: none;
    background: none;
    border: none;
    outline: none;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 0px 0px 16px 16px  ;
    box-shadow: 0px 5px rgba(0, 0, 0, 0.2);
    color: #313131;
    font-size: 20px;
    transition: 0.4s ease;
    margin-top: -25px;
}

.search-bar:focus{
    background-color: rgba(255, 255, 255, 0.8)
}

.location-box .location {
    color: #FFF;
    font-size: 50px;
    font-weight: 500;
    text-align: center;
    text-shadow: 3px 3px rgba(50, 50, 70, 0.5);
}

.date-box .date {
    color: #FFF;
    font-size: 30px;
    font-weight: 300;
    font-style: italic;
    text-align: center;
    text-shadow: 2px 2px rgba(50, 50, 10, 0.5);
}

.weather-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center; 
}

.weather-box .temp {
    margin: 30px auto;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    padding: 15px 25px;
    color: #FFF;
    font-size: 95px;
    font-weight: 700;
    text-shadow: 3px 6px rgba(50, 50, 70, 0.5);
    text-align: center;
    box-shadow: 3px 6px rgba(0, 0, 0, 0.2) ;
}

.weather-box .description {
    color: #FFF;
    font-size: 46px;
    font-weight: 700;
    text-shadow: 3px 3px rgba(50, 50, 70, 0.5);
}

.weatherCard-container {
    margin-bottom: 76px;
    margin-top: 30px;
    @media screen and (min-width: 768px) { 
        margin-top: 90px;
    }
}

.add-to-favorites {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    height: 130px;
    @media (min-width: 1230px) {
        height: 30%;
    }

    .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 65%;
        @media (min-width: 1230px) {   
            height: 30%;
        }
        .btnFav {
            width: 100%;
            font-family: 'montseratt', sans-serif;
            text-shadow: 1px 2px rgba(252, 252, 252, 0.6);
            border: 1px solid #f1f1f1;
            background-color: transparent;
            border-radius: 14px;
            font-size: 30px;
            font-weight: 500;
            color: #000;  
            @media (min-width: 1230px) {
                font-size: 35px;
                height: 100%;
            }
            .faHeart {
                color: #c50017;
            }
        }
        .btnFav:hover {
            color: #a1893b;
            background-color: white;
        }
        .btnFav:hover > .faHeart{
            color: #ff001e;   
        }
    }
    .city-exists{
        font-family: 'montseratt', sans-serif;
        font-size: 20px;
        color: #ffffff;
        text-shadow: 2px 3px rgba(50, 50, 70, 0.5);
        @media (min-width: 1230px) {
            font-size: 30px;
        }
    }
}

.unitChange{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    .unitName{
        width: 55%;
        color: #FFF;
        font-size: 22px;
        font-weight: 300;
        font-style: italic;
        text-align: center;
        text-shadow: 2px 2px rgba(50, 50, 10, 0.5);
        @media screen and (min-width: 768px) { 
            font-size: 30px;
        }
    }
    .changeUnit {
        width: 55%;
        color: #FFF;
        font-size: 22px;
        font-weight: 300;
        font-style: italic;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.15);
        text-shadow: 2px 2px rgba(50, 50, 10, 0.5);
        box-shadow: 2px 3px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        @media screen and (min-width: 768px) { 
            font-size: 30px;
        }
    }
    .changeUnit:hover{
        background-color: rgba(255, 255, 255, 0.521); 
    }
}

.typeMalfunction {
    border: 1px solid;
    border-radius: 5px;
    margin: 10px 0px;
    padding: 5px 10px;
    background-repeat: no-repeat;
    background-position: 10px center;
    font-size: 20px;
    color: #4F8A10;
    background-color: #DFF2BF;
}




